import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SettingsComponent } from "./shared/components/settings/settings.component";
import { LogoutComponent } from "./shared/components/logout/logout.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";

const routes: Routes = [
  {
    path: "workflow",
    loadChildren: () =>
      import("./modules/workflow/workflow.module").then(
        (m) => m.WorkflowModule
      ),
  },

  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
  },

  {
    path: "help",
    loadChildren: () =>
      import("./modules/help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "me/settings",
    component: SettingsComponent,
  },
  { path: "logout", component: LogoutComponent },
  { path: "not-found", component: NotFoundComponent },

  {
    path: "",
    pathMatch: "full",
    redirectTo: "workflow",
  },

  { path: "**", redirectTo: "not-found" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
