import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  private timezone = new Date().getTimezoneOffset().toString();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.baseUrl)) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Request-Origin': 'et-adm',
          Timezone: this.timezone,
        },
      });
    }
    return next.handle(req);
  }
}
