import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KeycloakService} from 'keycloak-angular';
import {NgxSpinnerService} from 'ngx-spinner';
import {first, forkJoin, Observable, pipe, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {EventMessageType} from './core/models/event-message-type.enum';
import {CustomerInfoService} from './core/services/customer-info.service';
import {LanguagesService} from './core/services/languages.service';
import {ModeService} from './core/services/mode.service';
import {SseEventsService} from './core/services/sse-events.service';
import {UserService} from './core/services/user.service';
import {ConnectedUser, UserLoggedService,} from './core/services/userLogged.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@UntilDestroy()
export class AppComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;
  public isTermsChecked: boolean = false;
  private onDestroy$: Subject<void> = new Subject();

  private connectedUser: ConnectedUser;

  constructor(
    private modeService: ModeService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private userLoggedService: UserLoggedService,
    private userService: UserService,
    private customerService: CustomerInfoService,
    private sseEvents: SseEventsService,
    private langService: LanguagesService,
    private keycloak: KeycloakService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.connectedUser = this.userLoggedService.reloadCurrentUser();
    this.isTermsChecked = this.connectedUser.eulaStatus;
    forkJoin([
      this.userService.getMe(),
      this.langService.getAllLanguages(), this.modeService.getAllModes(),
      this.customerService.fetchCustomerInfo(), this.initLanguage()
    ])
      .pipe(
        pipe(first())
      )
      .subscribe(() => {
        this.subscribeToLogOutEvent();
        this.isLoaded = true;
        this.spinner.hide();
      });

  }

  ngOnDestroy() {
    this.sseEvents.stopListening();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private subscribeToLogOutEvent(): void {

    this.sseEvents.startListening();

    this.sseEvents
      .on(EventMessageType.SSO_LOGOUT_EVENT)
      .pipe(
        untilDestroyed(this),
        tap((event) => {
          if (event) {
            this.keycloak.logout().then();
          }
        })
      )
      .subscribe();

  }

  private initLanguage(): Observable<any> {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en-GB');
    this.translate.addLangs(['en-GB', 'en-US', 'es-ES', 'fr-FR', 'ru-RU']);

    const browserLang = this.translate.getBrowserLang();
    // get the language in which the user was using the app lately
    const userLang = this.connectedUser.language;

    this.userLoggedService.firstUpdateLanguage(userLang).subscribe();

    if (userLang.match(/es-ES|en-GB|en-US|fr-FR|ru-RU|ar-SA|de-DE/)) {
      return this.translate.use(userLang);
    }
    if (!userLang.match(/es-ES|en-GB|fr-FR|ru-RU|ar-SA|de-DE/)) {
      return this.translate.use(
        browserLang.match(/es-ES|en-GB|en-US|fr-FR|ru-RU|ar-SA|de-DE/)
          ? browserLang
          : 'en-GB'
      );
    }
  }


  public receiveIsTermsChecked(checked: boolean): void {
    this.isTermsChecked = checked;
  }
}
