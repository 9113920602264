import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {initializer} from './utils/app-init';

import {LayoutModule} from '@angular/cdk/layout';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CoreModule, KeycloakAngularModule, KeycloakService,} from 'keycloak-angular';
import {NgxSpinnerModule} from 'ngx-spinner';

import {HttpHeaderInterceptor} from './core/interceptors/http-header.interceptor';
import {SharedModule} from './shared/shared.module';
import {environment} from '../environments/environment';
import {httpParameterCleanerInterceptor} from './core/interceptors/http-parameter-cleaner.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.babelApiUrl + '/translations/et-adm/lang/',
    ''
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    CoreModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    LayoutModule,
    [KeycloakAngularModule],
    TranslateModule.forRoot({
      defaultLanguage: 'en-GB',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    provideHttpClient(
      withInterceptors([httpParameterCleanerInterceptor])
    )
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
