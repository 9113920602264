<ngx-spinner bdOpacity="1" bdColor="#333333" size="large" color="#f00"
  type="ball-clip-rotate" [fullScreen]="true">
  <img id="splash_logo" src="..\assets\img\logo.png" />
</ngx-spinner>

<div class="easytrack-app">
  <!--Check terms-->
  <ng-container *ngIf="isLoaded && !isTermsChecked">
    <div id="content-check-terms">
      <app-check-terms (isTermsCheckedEvent)="receiveIsTermsChecked($event)"></app-check-terms>
    </div>
  </ng-container>

  <ng-container *ngIf="isLoaded && isTermsChecked">

    <header>
      <app-header></app-header>
    </header>

    <main>
      <router-outlet></router-outlet>
    </main>

    <footer>
      <app-copyrights></app-copyrights>
    </footer>
    <app-message></app-message>
    <app-notification-toast-container></app-notification-toast-container>
  </ng-container>

</div>