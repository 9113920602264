import {KeycloakService} from 'keycloak-angular';

import {environment} from '../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: environment.keycloak,
          initOptions: {
            onLoad: "login-required",
            checkLoginIframe: false,
            responseMode: "fragment",
            flow: "standard",
          },
        });
        resolve();
      } catch (error) {
        reject();
      }
    });
  };
}
