import {HttpInterceptorFn, HttpParams, HttpRequest} from '@angular/common/http';

const isNull = (value: string) => {
  return value === null ||
    value === undefined ||
    value === 'undefined' ||
    value === 'null';
};

export function cleanRequestParams(req: HttpRequest<unknown>) {
  return req.clone({
    params: req.params.keys().reduce((params, key) => {
      const value = req.params.get(key);
      if (!isNull(value)) {
        return params.set(key, value);
      }
      return params;
    }, new HttpParams())
  });
}

export const httpParameterCleanerInterceptor: HttpInterceptorFn = (req, next) => {
  return next(cleanRequestParams(req));
};
